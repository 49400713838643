import React from "react"
import { Container, Button, Row, Col, Carousel, CarouselItem } from "react-bootstrap"

import one from "../../images/image/Wald-Anfang_rez.jpg"
import three from "../../images/image/Holzrückung.jpg"
import four from "../../images/image/Holztransport LKW.jpg"
import six from "../../images/image/Platten.jpg"
import seven from "../../images/image/Presse.jpg"
import eight from "../../images/image/Schnittholz.jpg"

const ImageCarousel = () => {
    return (
        <Container>
            <Carousel>
            <CarouselItem>
                    <img className="d-block carouselImage" src={one} width={800} />
                </CarouselItem>
                <CarouselItem>
                    <img className="d-block carouselImage" src={four} width={800} />
                </CarouselItem>
                <CarouselItem>
                    <img className="d-block carouselImage" src={three} width={800} />
                </CarouselItem>
                <CarouselItem>
                    <img className="d-block carouselImage" src={seven} width={800} />
                </CarouselItem>
                <CarouselItem>
                    <img className="d-block carouselImage" src={six} width={800} />
                </CarouselItem>
                <CarouselItem>
                    <img className="d-block carouselImage" src={eight} width={800} />
                </CarouselItem>
            </Carousel>
        </Container>
    )
}

export default ImageCarousel
